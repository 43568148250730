import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import './App.css';

import Home from "./pages/Home";
import Teaching from "./pages/Teaching";
import Projects from "./pages/Projects";

const trackingId = "G-SJWYYXW2TN";
ReactGA.initialize(trackingId);

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/"><Home /></Route>
                <Route path="/projects"><Projects /></Route>
                <Route path="/teaching"><Teaching /></Route>
            </Switch>
        </Router>
    );
};

export default App;
