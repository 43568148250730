import { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import LaunchIcon from "@material-ui/icons/Launch";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./Home.css";

import Constants from "../App.config";

import Header from "../components/Header";
import ShowcaseItem from "../components/ShowcaseItem";
import ContactMe from "../components/ContactMe";
import Footer from "../components/Footer";
import Section, { Fragment } from "../components/Section";

export const renderShowcaseItems = () => Constants.Showcase.map((projectId) => {
    const project = Constants.Projects[projectId];

    return (
        <ShowcaseItem
            key={`sh-${projectId}`}
            title={project.title}
            subtitle={project.subtitle}
            logo={project.logo}
            description={project.description}
            ctaCaption={project.ctaCaption}
            ctaUrl={project.ctaUrl}
            ctaIcon={project.isCtaExternal ? <LaunchIcon /> : null}
            role={project.role}
            color={project.color}
            colorLighter={project.colorLighter}
            colorDarker={project.colorDarker}
            illustration={project.illustration}
        />
    );
});

const Home = (props) => {
    const { pathName } = useLocation();
    useEffect(() => { window.scrollTo(0, 0); }, [pathName]);
    useEffect(() => { ReactGA.send({ hitType: "pageview", page: "/", title: "Home" }); }, []);

    return (<>
        <Helmet>
            <title>{Constants.Titles.Home}</title>
        </Helmet>

        <Header />

        <div className="hero">
            <div className="hero-fragment">
                <h1>{Constants.Tagline.Line1}</h1>
                <h1>{Constants.Tagline.Line2}</h1>
                <h1>{Constants.Tagline.Line3}</h1>
                <h1><mark>{Constants.Tagline.Line4}</mark></h1>

                {/* <Button caption="Contact me" /> */}
            </div>
        </div>

        <Section
            textColor="var(--color-secondary-lighter)"
            backgroundColor="var(--color-primary-darker)"
            flexDirection="row"
        >
            <Fragment side="left">
                <h2>{Constants.Profile.Introduction.title}</h2>
            </Fragment>

            <Fragment side="right">
                <p className="body-text">{Constants.Profile.Introduction.intro}</p>
            </Fragment>
        </Section>

        <div className="showcase">
            <h2>My works</h2>

            {renderShowcaseItems()}

            <Link to="/projects" className="link-all-projects">
                <p>View all of my projects</p>
                <ArrowForwardIcon classes={{ root: "forward-icon" }} />
            </Link>
        </div>

        <ContactMe />

        <Footer
            routes={["teaching"]}
            otherCopyrights={Constants.Copyrights.home}
        />
    </>);
};

export default Home;