import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import LaunchIcon from "@material-ui/icons/Launch";
import "./Teaching.css";

import Constants from "../App.config";

import Header from '../components/Header';
import ContactMe from '../components/ContactMe';
import Footer from '../components/Footer';
import Section, { Fragment } from '../components/Section';
import BlobItem from "../components/BlobItem";

const renderTeachingItems = () => Constants.Teachings.map((mod) => (
    <BlobItem
        key={`mod-${mod.code}`}
        title={mod.code}
        subtitle={mod.title}
        description={mod.description}
        ctaCaption={mod.ctaCaption}
        ctaUrl={mod.ctaUrl}
        ctaIcon={mod.isCtaExternal ? <LaunchIcon /> : null}
        color="#447E61"
        colorLighter="#D8FFD5"
        colorDarker="#144840"
        illustration={mod.illustration}
        metadata={[
            {
                title: "MY ROLE",
                value: mod.role
            },
            {
                title: "MODULE COORDINATOR",
                value: mod.coordinator
            },
            {
                title: "PERIOD OF DUTY",
                value: mod.period
            }
        ]}
    />
));

const Teaching = (props) => {
    const { pathName } = useLocation();
    useEffect(() => { window.scrollTo(0, 0); }, [pathName]);
    useEffect(() => { ReactGA.send({ hitType: "pageview", page: "/teaching", title: "Teaching" }); }, []);

    return (<>
        <Helmet>
            <title>{Constants.Titles.Teaching}</title>
        </Helmet>

        <Header focus="teaching" />

        <Section
            textColor="var(--color-primary-darker)"
            backgroundColor="white"
            flexDirection="row"
            style={{ paddingTop: "8rem" }}
        >
            <Fragment side="left">
                <h2>{Constants.Profile.Teaching.title}</h2>
            </Fragment>

            <Fragment side="right">
                <p className="body-text">{Constants.Profile.Teaching.intro}</p>
            </Fragment>
        </Section>

        <div className="showcase" style={{ paddingTop: 0 }}>
            {renderTeachingItems()}
        </div>

        <ContactMe />

        <Footer
            routes={["projects"]}
            otherCopyrights={Constants.Copyrights.project}
        />
    </>);
};

export default Teaching;