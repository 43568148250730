import React from 'react';
import "./BlobItem.css";

import Button from "../components/Button";

const BlobItem = (props) => {
    const {
        logo,
        title,
        subtitle,
        description,
        ctaCaption,
        ctaUrl,
        ctaIcon,
        color,
        colorLighter,
        colorDarker,
        illustration,
        metadata
    } = props;

    const renderMetadata = () => metadata.map((data) => (<React.Fragment key={title}>
        <h4 style={{ color }}>{data.title}</h4>
        <h3 style={{ color: colorDarker }}>{data.value}</h3>
    </React.Fragment>));

    return (
        <div className="blob-item-container" key={props.id} style={{ backgroundColor: colorLighter }}>
            <div className="blob-item">
                <div className="blob-item-fragment blob-item-fragment-left">
                    {logo ? <img src={logo} alt={`${title} Logo`} className="blob-item-logo" /> : null}

                    <h2 style={{ color: colorDarker }}>{title}</h2>
                    <h3 style={{ color }}>{subtitle}</h3>

                    {metadata ? renderMetadata() : null}

                    <p className="body-text" style={{ color }}>{description}</p>

                    {ctaUrl ? <Button caption={ctaCaption} color={colorDarker} icon={ctaIcon || null} url={ctaUrl} /> : null}
                </div>

                {illustration ?
                    <div className="blob-item-fragment blob-item-fragment-right">
                        <img src={illustration} alt={`${title} illustration`} style={{ width: "100%" }} />
                    </div>
                : null}
            </div>
        </div>
    );
};

export default BlobItem;